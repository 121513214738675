.iconParagraphList {
  padding: 112px 0 96px;
  background: var(--white);
}

.paragraphs {
  display: grid;
  grid-gap: 92px 10%;
  grid-template-columns: repeat(2, 1fr);
}

.title {
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0.2px;
  line-height: 32px;
  margin-bottom: 12px;
}

.paragraph {
  display: flex;

}

.icon {
  margin-right: 42px;
}

.top {
  max-width: 516px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 88px;
}

.buttonWrapper {
  margin-top: 42px;
}

@media screen and (max-width: 1024px) {
  .iconParagraphList {
    padding: 64px 0;
  }
  .paragraphs {
    grid-template-columns: repeat(1, 1fr);
  }
}
