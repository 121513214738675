.testimonials {
  background: linear-gradient(0deg, #f6f8fb 0%, #ffffff 100%);
  padding: 64px 0 128px;
}

.row {
  display: flex;
  margin-bottom: 48px;
}

.content {
  padding-top: 80px;
  width: 40%;
  display: flex;
  flex-direction: column;
}

.text {
  margin-bottom: 24px;
}

.headings {
  width: 50%;
}

.navigation {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-content: flex-end;
  align-items: flex-end;
}

.content > * {
  max-width: 300px;
}

.item {
  background: var(--white);
  padding: 48px;
  border-radius: 12px;
  max-width: 732px !important;
  width: calc(100vw - 48px) !important;
}

.slide {
  overflow: visible !important;
}

.itemTitle {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 38px;
  color: var(--black);
  margin-bottom: 24px;
}

.itemTitle:before {
  content: "";
  display: block;
  width: 49px;
  height: 35px;
  background: url("./quote.svg") 0 0 no-repeat;
  margin-bottom: 32px;
}

.itemContent {
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 28px;
  color: var(--black);
  margin-bottom: 24px;
}

.itemAuthor {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 24px;
  color: var(--black);
  text-transform: uppercase;
  margin-bottom: 8px;
}

.itemOrganisation {
  color: var(--lightGray);
  font-size: 12px;
  letter-spacing: 0.2px;
  line-height: 18px;
}

.prev,
.next {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  border: 1px solid var(--lightGray);
  display: flex;
  justify-content: center;
  align-items: center;
}

.prev:hover,
.next:hover {
  border: 1px solid var(--green);
}

.prev svg,
.next svg {
  transition: transform 0.2s ease-in-out;
}

.prev:hover svg {
  transform: translateX(4px);
}
.next:hover svg {
  transform: translateX(4px);
}

.prev {
  transform: rotate(180deg);
  margin-right: 22px;
}

.slider {
  width: calc(50vw + 510px) !important;
}

.sliderWrapper {
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .testimonials {
    padding: 48px 0 96px;
  }
  .item {
    padding: 48px 32px;
  }
}

@media screen and (max-width: 768px) {
  .row {
    margin-bottom: 32px;
    flex-direction: column;
  }

  .row > * {
    width: 100%;
  }

  .navigation {
    margin-top: 16px;
    justify-content: flex-start;
  }
  .item {
    padding: 32px 16px;
  }
}
