.imagesAsideAnimated {
  padding: 112px 0 96px;
  background: var(--white);
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.images {
  position: relative;
  width: 50%;
  height: 562px;
}

.images img {
  border-radius: 12px;
  overflow: hidden;
}

@keyframes slideUp {
  from {
    bottom: 0;
  }

  to {
    bottom: 100%;
  }
}

@keyframes slideDown {
  to {
    top: 100%;
  }
  from {
    top: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}

.imageFirst {
  position: absolute;
  top: 0%;
  right: 54px;
}

.imageSecond {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.imageThird {
  position: absolute;
  bottom: 0;
  right: 0;
}

.imageFirst:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: var(--white);
  animation: 0.75s slideUp ease-in forwards 0.25s;
}

.imageSecond:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: var(--white);
  animation: 0.75s slideDown ease-in forwards 0.75s;
}

.imageThird:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: var(--white);
  animation: 0.5s slideUp ease-in forwards 1.25s;
}

.content {
  width: 50%;
  padding-right: 10%;
}

.imageAsideWide .image {
  width: 40%;
}

.imageAsideWide .content {
  width: 60%;
  padding-right: 10%;
}

.buttonWrapper {
  margin-top: 48px;
}

.badge {
  position: absolute;
  top: 174px;
  right: 22px;
  z-index: 2;
  border-radius: 50%;
}

.badge {
  opacity: 0;
  animation: 0.5s fadeIn forwards ease-in-out 1.75s;
}

.mobileImage {
  display: none;
}

@media screen and (max-width: 768px) {
  .imageAside {
    padding: 48px 0 48px;
  }

  .content {
    padding-right: 0;
  }

  .row {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  .images {
    width: 90%;
    margin-bottom: 32px;
  }

  .content {
    width: 100%;
  }

  .badge {
    right: 22px;
  }

  .imageAside .content,
  .imageAside .image {
    width: 100%;
    padding-right: 0;
  }
}

@media screen and (max-width: 768px) {
  .images {
    width: 100%;
    height: auto;
  }

  .imageFirst, .imageSecond, .imageThird, .badge {
    display: none;
  }

  .mobileImage {
    display: block;
  }
}
