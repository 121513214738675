.valuesIntro {
  padding: 112px 0 96px;
  background: var(--white);
}

.items {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 24px;
}

.item {
  min-height: 202px;
  overflow: hidden;
  position: relative;
  border-radius: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: var(--offWhite);
}

.value {
  font-size: 72px;
  line-height: 72px;
  margin-bottom: 16px;
  font-weight: 500;
  color: var(--blue);
}

.label {
  text-align: center;
}

.number {
  font-size: 28px;
  line-height: 44px;
  font-weight: 500;
}

.itemTitle {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 32px;
  text-align: center;
}

.itemText {
  color: var(--white);
  text-align: center;
  display: flex;
  align-items: center;
  align-items: center;
  justify-content: center;
  display: none;
  padding: 16px;
}

.item:hover .itemText {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
}

.top {
  margin: 0 auto;
  max-width: 624px;
  margin-bottom: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.item p {
  cursor: default;
}

.item:nth-child(1) .number {
  color: #2266C5;
}
.item:nth-child(1) .itemText {
  background: #2772C6;
}
.item:nth-child(2) .number {
  color: #2772C6;
}
.item:nth-child(2)  .itemText {
  background: #008077;
}
.item:nth-child(3) .number {
  color: #008077;
}
.item:nth-child(3)  .itemText {
  background: #008077;
}
.item:nth-child(4) .number {
  color: #008077;
}
.item:nth-child(4)  .itemText {
  background: #008077;
}
.item:nth-child(5) .number {
  color: #04AA55
}
.item:nth-child(5)  .itemText {
  background: #04AA55;
}

.overlay {
  position: absolute;
  opacity: 0;
  transform: translateY(30%);
  top: 0;
  transition: transform 0.3s ease-out, opacity 0.3s ease-in;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 13px;
  color: var(--white);
  padding: 32px;
  text-align: center;
}

.overlay .text {
  margin-top: 16px;
}

.card:hover .overlay {
  transform:translateY(0);
  opacity: 1;
}

@media screen and (max-width: 1024px) {
  .items {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media screen and (max-width: 560px) {
  .valuesIntro {
    padding: 96px 0 96px;
    background: var(--white);
  }

  .numbers {
    padding: 64px 0 48px;
  }

  .items {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .itemsWrapper {
    max-width: 100%;
    overflow-x: scroll;
  }
  .items {
    display: flex;
    flex-wrap:nowrap;
  }

  .item {
    width: 80%;
    margin-right: 32px;
    flex-shrink: 0;
  }

  .top {
    align-items: flex-start;
    text-align: left;
    flex-direction: column;
  }

  .top > *:first-child {
    margin-bottom: 16px;
  }
}
