.contactForm .form {
  background: var(--offWhite);
  padding: 40px;
  border-radius: 12px;
  width: 70%;
}

.formFooter a {
  color: var(--green);
  text-decoration: underline;
}

.formFooter a:hover {
  color: var(--black);
}

.contactForm {
  padding-bottom: 112px;
}

.row {
  display: flex;
}

.contactForm .success {
  text-align: center;
  padding-top: 90px;
}

.subtitle {
  margin-bottom: 40px;
}

.contactForm textarea,
.contactForm input[type="email"],
.contactForm input[type="text"]:not(:global(.select__input)) {
  border: 1px solid var(--lightGray);
  border-radius: 8px;
  background-color: var(--white);
  line-height: 28px;
  font-size: 16px;
  padding: 13px 20px;
  width: 100%;
  height: 56px;
}

.contactForm textarea:hover,
.contactForm input[type="email"]:hover,
.contactForm input[type="text"]:hover {
  border: 1px solid var(--green);
}

.contactForm textarea:focus,
.contactForm input[type="email"]:focus,
.contactForm input[type="text"]:focus {
  outline: none;
}

.fieldRow {
  display: flex;
  flex-direction: row;
}

.fieldRow .fieldWrapper {
  width: 50%;
}

.fieldWrapper {
  margin-bottom: 32px;
  position: relative;
}

.fieldWrapper .textLimit {
  font-size: 12px;
  letter-spacing: 0.2px;
  line-height: 18px;
  position: absolute;
  top: 0;
  right: 0;
  color: var(--lightGray);
}

.contactForm label {
  display: block;
  margin-bottom: 6px;
  color: var(--gray);
  font-size: 14px;
  letter-spacing: 0.2px;
  line-height: 26px;
}

.contactForm .labelWrapper label {
  display: inline-block;
}

.labelWrapper > * {
  vertical-align: middle;
  display: inline-block;
  margin-right: 12px;
  margin-bottom: 6px;
}

.contactForm .fieldRow .fieldWrapper {
  width: calc(50% - 12px);
}

.contactForm .fieldRow {
  display: flex;
  justify-content: space-between;
}

.formFooter {
  text-align: center;
  padding-top: 32px;
  margin-top: 32px;
  border-top: 1px solid var(--lighterGray);
}

.formFooter .terms {
  max-width: 300px;
  margin: 24px auto 0;
  color: var(--gray);
  font-size: 14px;
  letter-spacing: 0.2px;
  line-height: 24px;
  text-align: center;
}

.contactForm :global(.select__control) {
  border: 1px solid var(--lightGray);
  border-radius: 8px;
  background-color: var(--white);
  line-height: 28px;
  font-size: 16px;
  padding: 13px 20px;
}

.contactForm :global(.select) {
  background: var(--white);
}

.contactForm :global(.select__value-container) {
  padding: 0;
}
.contactForm :global(.select__control--is-focused) {
  box-shadow: none;
  background: none;
}
.contactForm :global(.select__control:active) {
  box-shadow: none;
  background: none;
}
.contactForm :global(.select__control:hover) {
  border: 1px solid var(--green);
}
.contactForm :global(.select__indicator-separator) {
  display: none;
}
.contactForm :global(.select__placeholder) {
  color: var(--lightGray);
}
.contactForm :global(.select__option) {
  padding: 8px 20px;
  cursor: pointer;
  background: var(--white)!important;
}
.contactForm :global(.select__option--is-focused) {
  color: var(--green);
  background: transparent;
}
.contactForm :global(.select__option--is-selected) {
  background: transparent;
  color: var(--black);
  font-weight: 500;
}

.contactForm input[type="file"] {
  width: 100%;
}

.contactForm input[type="file"]:hover {
  cursor: pointer;
}

.contactForm input[type="file"]::-webkit-file-upload-button {
  font-size: 12px;
  line-height: 24px;
  height: 40px;
  letter-spacing: 0.5px;
  padding: 7px 31px;
  text-transform: uppercase;
  border-radius: 29px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in;
  min-width: 140px;
  font-weight: 500;
  border: 1.5px solid transparent;
  border-radius: 30px;
  background-image: linear-gradient(white, white),
    linear-gradient(to left, #5bc490, #4da884);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  mix-blend-mode: darken;
  text-shadow: none;
  box-shadow: none;
  appearance: none;
  cursor: pointer;
}

.contactForm input[type="file"]::file-upload-button {
  font-size: 12px;
  line-height: 24px;
  height: 40px;
  letter-spacing: 0.5px;
  padding: 7px 31px;
  text-transform: uppercase;
  border-radius: 29px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in;
  min-width: 140px;
  font-weight: 500;
  border: 1.5px solid transparent;
  border-radius: 30px;
  background-image: linear-gradient(white, white),
    linear-gradient(to left, #5bc490, #4da884);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  mix-blend-mode: darken;
  text-shadow: none;
  box-shadow: none;
  appearance: none;
  cursor: pointer;
  margin-right: 16px;
}
.contactForm input[type="file"]::file-selector-button {
  font-size: 12px;
  line-height: 24px;
  height: 40px;
  letter-spacing: 0.5px;
  padding: 7px 31px;
  text-transform: uppercase;
  border-radius: 29px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in;
  min-width: 140px;
  font-weight: 500;
  border: 1.5px solid transparent;
  border-radius: 30px;
  background-image: linear-gradient(white, white),
    linear-gradient(to left, #5bc490, #4da884);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  mix-blend-mode: darken;
  text-shadow: none;
  box-shadow: none;
  appearance: none;
  cursor: pointer;
  margin-right: 16px;
}

.contactForm input[type="file"]:hover::-webkit-file-upload-button {
  background: var(--green);
  color: var(--white);
}

.contactForm input[type="file"]:hover::file-upload-button {
  background: var(--green);
  color: var(--white);
}

.contactForm input[type="file"]:hover::file-selector-button {
  background: var(--green);
  color: var(--white);
}

.validationMessage {
  color: #ff3434;
  font-size: 12px;
  letter-spacing: 0.2px;
  line-height: 18px;
}

.aside {
  padding-left: 80px;
  width: 30%;
}

.asideInner {
  border-top: 1px solid var(--lighterGray);
  padding-top: 32px;
}

.asideRow {
  margin-bottom: 16px;
  padding-left: 36px;
  position: relative;
  color: var(--lightGray);
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 28px;
}

.asideRow a {
  margin-top: 16px;
  margin-bottom: 16px;
}

.asideRow > svg {
  position: absolute;
  top: 2px;
  left: 0;
}

.asideRow > svg,
.asideRow > svg * {
  fill: var(--lightGray);
}

.asideFooter {
  margin-top: 32px;
  padding-top: 32px;
  border-top: 1px solid var(--lighterGray);
}

.social {
  text-align: right;
}

.social {
  display: flex;
  flex-direction: row;
}

.socialIcon svg {
  opacity: 70%;
}

.socialIcon svg * {
  fill: var(--lightGray);
}

.socialIcon svg:hover * {
  fill: var(--black);
}

.socialIcon:not(:last-child) {
  margin-right: 24px;
}

@media screen and (max-width: 1024px) {
  .row {
    flex-direction: column;
  }

  .contactForm .fieldRow .fieldWrapper {
    width: 100%;
  }

  .contactForm  .fieldRow {
    display: block;
  }

  .asideInner {
    border-top: 0px;
  }

  .aside {
    padding-left: 0;
    width: 100%;
  }

  .contactForm .form {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .contactForm .form {
    padding: 32px 20px;
  }
}
