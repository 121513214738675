.imageAsideSwitch {
  padding: 112px 0 146px;
  background: var(--white);
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.text {
  margin-bottom: 24px;
  color: var(--black);
}

.imageWrapper {
  position: relative;
  width: 40%;
}

.imageHidden {
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
}

.image img {
  border-radius: 12px;
  overflow: hidden;
}

.content {
  width: 50%;
}

.buttonWrapper {
  margin-top: 48px;
}

.badge {
  position: absolute;
  top: 24px;
  left: -24px;
  z-index: 2;
  border-radius: 50%;
}

.accordionItem {
  cursor: pointer;
  padding-left: 16px;
  color: var(--lightGray);
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 28px;
  position: relative;
}

.accordionItem:not(:last-child) {
  margin-bottom: 16px;
}

.accordionItemActive:before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 1px;
  content: "";
  display: block;
  background: linear-gradient(180deg, #00cf82 0%, #03815B 100%);
}

.accordionItem:not(.accordionItemActive):hover {
  color: var(--green);
}

.accordionItemActive {
  color: var(--black);
  font-weight: 500;
}

@media screen and (max-width: 1024px) {
  .imageAsideSwitch {
    padding: 64px 0;
  }
  .row {
    flex-direction: column;
  }
  .content,
  .imageWrapper {
    width: 100%;
  }

  .content {
    margin-top: 32px;
  }

  .badge {
    left: -12px;
  }
}

@media screen and (max-width: 768px) {
  .imageAsideSwitch {
    padding: 48px 0;
  }
}
