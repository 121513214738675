.imageAsideAlternative {
  padding: 64px 0 96px;
  background: var(--white);
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: var(--offWhite);
  border-radius: 12px;
  align-items: stretch;
}

.image {
  position: relative;
  width: 50%;
  font-size: 0;
}

.text {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 38px;
  color: var(--black);
}

.image img {
  border-radius: 12px 0 0 12px;
}

.content {
  width: 50%;
  padding-right: 9%;
  padding-left: 9%;
  padding-top: 48px;
  padding-bottom: 48px;
}

.wide .image {
  width: 40%;
  align-content: stretch;
  align-items: stretch;
}

.wide .content {
  width: 60%;
}

.buttonWrapper {
  margin-top: 48px;
}

.badge {
  position: absolute;
  top: 28px;
  right: -24px;
  z-index: 2;
  border-radius: 50%;
}

.imageWrapper {
  position: relative;
  align-items: stretch;
  align-content: stretch;
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 768px) {
  .imageAside {
    padding: 48px 0 48px;
  }

  .text {
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
  }

  .image {
    width: 100%;
    padding-bottom: 100%;
    margin-bottom: 0;
  }

  .image img {
    border-radius: 12px;
  }

  .content {
    padding-top: 32px;
    padding-bottom: 32px;
    padding: 32px;
  }

  .row {
    flex-direction: column;
  }

  .imageAsideAlternative .content,
  .imageAsideAlternative .image {
    width: 100%;
  }
}
