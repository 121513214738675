.experiencesListing {
  background: var(--offWhite);
  padding: 96px 0 128px;
}

.headingWrapper h2 {
  margin-bottom: 64px;
  text-align: center;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.experience {
  display: flex;
  flex-direction: column;
  min-height: 420px;
  padding: 32px;
  width: 50%;
  background: var(--white);
  border-radius: 12px;
  margin-bottom: 24px;
}

.content {
  flex: 1;
  margin-bottom: 12px;
}

.experience:nth-child(4n + 1) {
  width: calc(50% - 12px);
}
.experience:nth-child(4n + 2) {
  width: calc(50% - 12px);
}
.experience:nth-child(4n + 3) {
  width: calc(60% - 12px);
}
.experience:nth-child(4n) {
  width: calc(40% - 12px);
}

.image {
  position: relative;
  width: 100%;
  height: 66px;
  margin-bottom: 24px;
}

.tags {
  position: relative;
}

.tag {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 24px;
  text-transform: uppercase;
  background: var(--offWhite);
  border-radius: 8px;
  display: inline-block;
  padding: 4px 12px;
  margin-right: 12px;
  margin-bottom: 12px;
}

.tagShow {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 24px;
  text-transform: uppercase;
  background: var(--offWhite);
  border-radius: 8px;
  display: inline-block;
  padding: 4px 12px;
  margin-right: 12px;
  margin-bottom: 4px;
}

.tagShow:hover {
  color: var(--green);
}

.tagHidden {
  display: none;
}

.areas {
  margin-bottom: 12px;
}

.area {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 24px;
  text-transform: uppercase;
  background: transparent;
  border-radius: 8px;
  border: 1px solid var(--gray);
  color: var(--gray);
  display: inline-block;
  padding: 4px 12px;
  margin-right: 12px;
}

@media screen and (max-width: 768px) {
  .experience:nth-child(n) {
    width: 100%;
  }
}
