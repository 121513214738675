.nextPrevNavigation {
  padding: 24px 0;
}
.next,
.prev {
  color: var(--gray);
  font-size: 14px;
  letter-spacing: 0.2px;
  line-height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.prev:hover,
.next:hover {
  color: var(--green);
}

.prev svg {
  transform: rotate(180deg);
}

.next svg {
  margin-left: 12px;
  transition: 0.2s transform ease-in-out;
}

.next {
  text-align: right;
}

.prev svg {
  margin-right: 12px;
  transition: 0.2s transform ease-in-out;
}

.prev:hover svg {
  animation: bounceRotated 1s infinite;
}
.next:hover svg {
  animation: bounce 1s infinite;
}

.prev:hover svg * {
  fill: var(--green);
}

.next:hover svg * {
  fill: var(--green);
}

@keyframes bounce {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(8px);
  }
}

@keyframes bounceRotated {
  0% {
    transform: translateX(0) rotate(180deg);
  }

  50% {
    transform: translateX(-8px) rotate(180deg);
  }
}

.container.container {
  overflow: visible;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
