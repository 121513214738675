.cardOnBackground {
  position: relative;
  background: var(--white);
}

.cardSpacingAbove {
  padding-top: 112px;
}

.background {
  position: relative;
  z-index: 2;
}

.inner {
  padding-bottom: 72%;
  border-radius: 12px;
  margin-left: -108px;
  margin-right: -108px;
  overflow: hidden;
  position: relative;
}

.card {
  position: absolute;
  bottom: 108px;
  left: 108px;
  z-index: 2;
  background: var(--white);
  width: 516px;
  padding: 64px 54px;
  border-radius: 12px;
}

.card-right {
  left: auto;
  right: 108px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.image {
  position: relative;
  width: 50%;
}

.text {
  color: var(--black);
}

.buttonWrapper {
  margin-top: 48px;
}

.image img {
  border-radius: 12px;
  overflow: hidden;
}

.content {
  width: 50%;
  padding-right: 10%;
}

.imageAsideWide .image {
  width: 40%;
}

.imageAsideWide .content {
  width: 60%;
  padding-right: 10%;
}

.buttonWrapper {
  margin-top: 48px;
}

.badge {
  position: absolute;
  top: -24px;
  right: -24px;
  z-index: 2;
  border-radius: 50%;
}

@media screen and (max-width: 1024px) {
  .inner {
    padding-bottom: 740px;
  }
}

@media screen and (max-width: 1024px) {
  .cardSpacingAbove {
    padding-top: 64px;
  }

  .background {
    padding-left: 0;
    padding-right: 0;
  }
  .inner {
    margin-left: 0;
    margin-right: 0;
    padding: 64px 32px;
    border-radius: 0;
  }

  .buttonWrapper {
    margin-top: 32px;
  }

  .card {
    position: relative;
    z-index: 3;
    transform: none;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 64px 32px;
  }

  .card-right {
    left: auto;
    right: 0;
  }
}

@media screen and (max-width: 768px) {
  .inner {
    padding: 96px 16px;
  }
}
