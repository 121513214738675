.values {
  padding: 112px 0 96px;
  background: var(--white);
}

.items {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 32px;
}

.item {
  overflow: hidden;
  position: relative;
  border-radius: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.value {
  font-size: 72px;
  line-height: 72px;
  margin-bottom: 16px;
  font-weight: 500;
  color: var(--blue);
}

.label {
  text-align: center;
}

.top {
  margin-bottom: 42px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.top > * {
  color: var(--gray);
}

.top > * {
  margin-bottom: 16px;
}

.item:nth-child(1) .value {
  color: #2266C5;
}
.item:nth-child(2) .value {
  color: #2772C6;
}
.item:nth-child(3) .value {
  color: #008077;
}
.item:nth-child(4) .value {
  color: #03815B
}

.overlay {
  position: absolute;
  opacity: 0;
  transform: translateY(30%);
  top: 0;
  transition: transform 0.3s ease-out, opacity 0.3s ease-in;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 13px;
  color: var(--white);
  padding: 32px;
  text-align: center;
}

.overlay .text {
  margin-top: 16px;
}

.card:hover .overlay {
  transform:translateY(0);
  opacity: 1;
}

@media screen and (max-width: 1024px) {
  .items {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media screen and (max-width: 560px) {
  .numbers {
    padding: 64px 0 48px;
  }

  .itemsWrapper {
    max-width: 100%;
    overflow-x: scroll;
  }

  .items {
    display: flex;
    flex-wrap:nowrap;
  }

  .label {
    width: 65vw;
  }

  .item {
    width: 65vw;
    margin-right: 32px;
    flex-shrink: 0;
  }


  .top {
    align-items: flex-start;
    text-align: left;
    flex-direction: column;
  }

  .top > *:first-child {
    margin-bottom: 16px;
  }
}
