.numbers {
  padding: 112px 0 96px;
  background: var(--white);
}

.cards {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 24px;
}

.card {
  overflow: hidden;
  position: relative;
  min-height: 280px;
  background: var(--offWhite);
  border-radius: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.number {
  font-size: 72px;
  line-height: 72px;
  margin-bottom: 16px;
  font-weight: 500;
  color: var(--blue);
}

.top {
  margin-bottom: 42px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.top > * {
  margin-bottom: 16px;
}

.card:nth-child(1) .number {
  color: #2266C5;
}
.card:nth-child(1) .overlay {
  background: #2266C5;
}
.card:nth-child(2) .number {
  color: #2772C6;
}
.card:nth-child(2) .overlay {
  background: #2772C6;
}
.card:nth-child(3) .number {
  color: #008077;
}
.card:nth-child(3) .overlay {
  background: #008077;
}
.card:nth-child(4) .number {
  color: #03815B
}
.card:nth-child(4) .overlay {
  background: #03815B
}

.overlay {
  position: absolute;
  opacity: 0;
  transform: translateY(30%);
  top: 0;
  transition: transform 0.3s ease-out, opacity 0.3s ease-in;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 13px;
  color: var(--white);
  padding: 32px;
  text-align: center;
}

.overlay .text {
  margin-top: 16px;
}

.card:hover .overlay {
  transform:translateY(0);
  opacity: 1;
}

@media screen and (max-width: 1024px) {
  .numbers {
    padding: 64px 0 64px;
  }
  .cards {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media screen and (max-width: 560px) {
  .numbers {
    padding: 48px 0 48px;
  }

  .cardsWrapper {
    max-width: 100%;
    overflow-x: scroll;
  }

  .cards {
    display: flex;
    flex-wrap:nowrap;
  }

  .card {
    width: 80%;
    margin-right: 32px;
    flex-shrink: 0;
  }

  .top {
    align-items: flex-start;
    text-align: left;
    flex-direction: column;
  }

  .top > *:first-child {
    margin-bottom: 16px;
  }
}
