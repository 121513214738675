.experiencesSlider {
  padding: 128px 0;
  background: var(--white);
}

.experience {
  display: flex;
  flex-direction: column;
  min-height: 420px;
  padding: 32px;
  width: 100%;
  background: var(--offWhite);
  border-radius: 12px;
  margin-bottom: 24px;
}

.row {
  display: flex;
  flex-direction: row;
  margin-bottom: 64px;
  align-content: flex-end;
  align-self: flex-end;
  justify-content: flex-end;
}

.topRight {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
}

.topLeft h2 {
  margin-bottom: 0;
}

.row > * {
  width: 50%;
}

.content {
  flex: 1;
  margin-bottom: 12px;
}

.image {
  position: relative;
  width: 100%;
  height: 66px;
  margin-bottom: 24px;
}

.tags {
  position: relative;
}

.tag {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 24px;
  text-transform: uppercase;
  background: #e9ecf1;
  border-radius: 8px;
  display: inline-block;
  padding: 4px 12px;
  margin-right: 12px;
  margin-bottom: 12px;
}

.tagShow {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 24px;
  text-transform: uppercase;
  background: var(--offWhite);
  border-radius: 8px;
  display: inline-block;
  padding: 4px 12px;
  margin-right: 12px;
  margin-bottom: 4px;
}

.tagShow:hover {
  color: var(--green);
}

.tagHidden {
  display: none;
}

.areas {
  margin-bottom: 12px;
}

.area {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 24px;
  text-transform: uppercase;
  background: transparent;
  border-radius: 8px;
  border: 1px solid var(--gray);
  color: var(--gray);
  display: inline-block;
  padding: 4px 12px;
  margin-right: 12px;
}

.sliderWrapper {
  margin-bottom: 24px;
}

.navigation {
  margin-top: 48px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
}

.prev,
.next {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  border: 1px solid var(--lightGray);
  display: flex;
  justify-content: center;
  align-items: center;
}

.prev:hover,
.next:hover {
  border: 1px solid var(--green);
}

.prev svg,
.next svg {
  transition: transform 0.2s ease-in-out;
}

.prev:hover svg {
  transform: translateX(4px);
}
.next:hover svg {
  transform: translateX(4px);
}

.prev {
  transform: rotate(180deg);
  margin-right: 22px;
}

@media screen and (max-width: 768px) {
  .row {
    flex-direction: column;
  }

  .row > * {
    width: 100%;
  }

  .topRight {
    margin-top: 16px;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
  }

  .bulletSlider {
    padding-top: 96px;
    padding-bottom: 96px;
  }
}
