.centeredIntro {
  padding: 96px 0 96px;
  background: var(--white);
}

.icon {
  width: 58px;
  height: 58px;
  margin: 0 auto;
  position: relative;
  margin-bottom: 24px;
}

.text {
  color: var(--black);
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 28px;
  text-align: center;
}

.content {
  max-width: 624px;
  margin: 0 auto;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .centeredIntro {
    padding: 64px 0;
  }
}
