.textVerticalSlider {
  padding: 96px 0 128px;
  background: var(--offWhite);
}

.icon {
  width: 58px;
  height: 58px;
  margin: 0 auto;
  position: relative;
  margin-bottom: 24px;
}

.row {
  left: 0;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;
}

.slider {
  width: 40%;
  position: relative;
  z-index: 10;
  height: 450px;
  overflow: hidden;
}

.slider:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 50%;
  right: 0;
  background: linear-gradient(
    180deg,
    #f6f8fb 0%,
    rgba(246, 248, 251, 0.63) 68.55%,
    rgba(246, 248, 251, 0) 100%
  );
  z-index: 10;
}

.slider:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  top: 50%;
  right: 0;
  transform: rotate(180deg);
  background: linear-gradient(
    180deg,
    #f6f8fb 0%,
    rgba(246, 248, 251, 0.63) 68.55%,
    rgba(246, 248, 251, 0) 100%
  );
  z-index: 10;
}

.sliderInner {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
}



.mark {
  position: absolute;
  top: 50%;
  left: 0px;
  height: 32px;
  width: 2px;
  border-radius: 1px;
  background: linear-gradient(180deg, #00cf82 0%, #03815B 100%);
}

.slider p {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 32px;
  color: var(--black);
  margin-bottom: 18px;
}

.imageWrapper {
  border-radius: 12px;
  position: absolute;
  left: 0;
  z-index: 10;
  top: 0;
  right: 0;
  overflow: hidden;
}

.iconWrapper {
  position: absolute;
  bottom: -300px;
  z-index: 30;
  left: 8px;
}

.content {
  width: 40%;
}

.text {
  color: var(--black);
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 28px;
}

.content {
}

@media screen and (max-width: 768px) {
  .textVerticalSlider {
    padding: 96px 0 32px;
  }

  .slider {
    height: 350px;
  }

  .centeredIntro {
    padding: 64px 0;
  }

  .slider,
  .content {
    width: 100%;
  }

  .row {
    flex-direction: column;
  }
}
