.imageAsideAccordion {
  padding: 112px 0 146px;
  background: var(--offWhite);
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.top {
  max-width: 624px;
  margin: 0 auto 80px;
  text-align: center;
}

.text {
  margin-bottom: 24px;
}

.image {
  position: relative;
  width: 50%;
}

.image img {
  border-radius: 12px;
  overflow: hidden;
}

.content {
  width: 50%;
  padding-left: 10%;
}

.buttonWrapper {
  margin-top: 48px;
}

.badge {
  position: absolute;
  top: 24px;
  left: -24px;
  z-index: 2;
  border-radius: 50%;
}

.itemAccordion {
  overflow-x: hidden;
  transition: 0.3s all;
}

.itemTitle {
  cursor: pointer;
  padding-left: 16px;
  color: var(--lightGray);
  margin-bottom: 16px;
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 28px;
  position: relative;
}

.itemTitleActive:before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 1px;
  content: "";
  display: block;
  background: linear-gradient(180deg, #00CF82 0%, #03815B 100%);
}

.itemTitleActive {
  color: var(--black);
  font-weight: 500;
}

.itemTitle:not(.itemTitleActive):hover {
  color: var(--green);
}

.itemText {
  padding-bottom: 24px;
  padding-left: 16px;
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 28px;
}

@media screen and (max-width: 1024px) {
  .imageAsideAccordion {
    padding: 64px 0;
  }
  .row {
    flex-direction: column;
  }
  .content,
  .image {
    padding-left: 0;
    width: 100%;
  }

  .content {
    margin-top: 32px;
  }
}
