.tooltip :global(.tooltip) {
  border-radius: 8px;
  background-color: var(--white);
  max-width: 200px;
  padding: 12px 20px;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.07);
  font-size: 12px;
  letter-spacing: 0.2px;
  line-height: 18px;
  text-align: left;
  color: var(--black);
}

.tooltip :global(.tooltip:before) {
  display: none;
}

.tooltip :global(.tooltip .multi-line) {
  text-align: left;
}

.tooltip svg,
.tooltip svg * {
  fill: var(--lightGray);
}

.tooltip:hover svg,
.tooltip:hover svg * {
  fill: var(--green);
}
