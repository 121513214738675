.bulletSlider {
  padding: 128px 0;
  background: var(--offWhite);
}

.row {
  display: flex;
  flex-direction: row;
  margin-bottom: 64px;
}

.row > * {
  width: 50%;
}

.image {
  position: relative;
  height: 304px;
}

.item {
  width: 90vw;
  max-width: 408px;
  padding-bottom: 96px;
}

.itemList {
  display: none;
  margin-top: 16px;
}

.itemList ul {
  list-style-type: none;
  list-style-position: inside;
}

.itemList li:not(:last-child) {
  margin-bottom: 10px;
}

.itemList li:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin-right: 16px;
  position: relative;
  top: -2px;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background: var(--green);
}

.itemBottomOpen .itemList {
  display: block;
}

.itemChevron {
  position: absolute;
  top: 42px;
  right: 32px;
  z-index: 3;
  transform: rotate(180deg);
}

.itemBottom:hover .itemChevron svg,
.itemBottom:hover .itemChevron svg * {
  fill: var(--green);
}

.itemBottomOpen .itemChevron {
  transform: rotate(0deg);
}

.slider {
  width: calc(50vw + 510px) !important;
}

.slide {
  width: 90vw !important;
  max-width: 408px !important;
  border-radius: 12px;
  overflow: hidden;
  flex-shrink: 0;
}

.itemBottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 28px 32px 36px;
  background: var(--white);
  cursor: pointer;
}

.itemBottom:before {
  content: "";
  display: block;
}

.itemBottom h2 {
  margin-bottom: 0;
}

.content {
  padding-top: 80px;
  width: 40%;
  display: flex;
  flex-direction: column;
}

.topRight {
  padding-right: 24px;
}

.text {
  margin-bottom: 24px;
}

.content > * {
  max-width: 300px;
}

.news {
  padding-bottom: 112px;
  background: var(--offWhite);
}

.newsWhite {
  overflow: hidden;
  padding-top: 114px;
  background: var(--white);
}

.newsWhite .slider {
  transform: translateY(0);
}

.newsItem {
  height: 512px;
  min-width: 410px;
  max-width: 410px;
  display: flex;
  align-items: flex-end;
}

.newsItem > * {
  transition: transform 0.15s ease-in-out;
}

.newsItem:not(.activeNewsItem) > * {
  display: block;
  opacity: 0.5;
  transition: all 0.15s ease-in-out;
}

.activeNewsItem {
  min-width: 410px;
  max-width: 410px;
  transition: background 0.15s ease-in-out;
}

.newsImage {
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.15s ease-in-out;
  margin-bottom: 24px;
}

.newsImage > *:first-child {
}

.labelWrapper {
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 10;
}

.newsTop {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 24px;
  color: var(--black);
  text-transform: uppercase;
  margin-bottom: 12px;
}

.newsExcerpt {
  margin-bottom: 12px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.newsAuthor {
  color: var(--lightGray);
  font-size: 12px;
  letter-spacing: 0.2px;
  line-height: 18px;
  margin-bottom: 24px;
}

.navigation {
  margin-top: 48px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.prev,
.next {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  border: 1px solid var(--lightGray);
  display: flex;
  justify-content: center;
  align-items: center;
}

.prev:hover,
.next:hover {
  border: 1px solid var(--green);
}

.prev svg,
.next svg {
  transition: transform 0.2s ease-in-out;
}

.prev:hover svg {
  transform: translateX(4px);
}
.next:hover svg {
  transform: translateX(4px);
}

.prev {
  transform: rotate(180deg);
  margin-right: 22px;
}

.navigationMobile {
  display: none;
}

@media screen and (max-width: 1024px) {
  .bulletSlider {
    padding-top: 96px;
    padding-bottom: 96px;
  }

  .slider {
    width: auto !important;
  }

  .row {
    flex-direction: column;
    margin-bottom: 48px;
  }
  .row > * {
    padding-top: 0;
    width: 100% !important;
  }

  .sliderWrapper {
    transform: translateY(0);
  }

  .navigation {
    margin-top: 24px;
    margin-bottom: 48px;
    transform: translateY(0px);
    display: none;
  }
  .navigationMobile {
    margin-top: 24px;
    margin-bottom: 48px;
    transform: translateY(0px);
    display: flex;
  }
}

@media screen and (max-width: 768px) {
  .bulletSlider {
    padding-top: 96px;
    padding-bottom: 96px;
  }
}
