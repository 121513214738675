.trakstar {
  padding: 128px 0;
  background: var(--white);
}

.title {
  font-size: 36px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 46px;
  text-align: center;
  color: var(--black);
  margin-bottom: 16px;
}

.subtitle {
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 28px;
  text-align: center;
  color: var(--black);
  margin-bottom: 64px;
}

.trakstar :global(.rbox-job-fullpage) {
  border-radius: 12px;
  padding-top: 32px;
}

.trakstar :global(.rbox-bottom-links) a,
.trakstar :global(.rbox-top-links) a {
  color: var(--gray);
}

.trakstar :global(.rbox-bottom-links) a:hover,
.trakstar :global(.rbox-top-links) a:hover {
  color: var(--black);
}

.trakstar :global(.rbox-opening-li) {
  padding: 32px;
  background: var(--offWhite);
  border-radius: 12px;
  border: 1px solid transparent !important;
  margin-bottom: 24px;
  position: relative;
}

.trakstar :global(.rbox-opening-li-title) {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 32px;
  color: var(--gray);
  margin-bottom: 6px;
}

.trakstar :global(.rbox-job-shortdesc) {
  color: var(--gray);
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 28px;
}

.trakstar :global(.rbox-opening-list .rbox-opening-position-info) {
  position: absolute;
  top: 50%;
  right: 32px;
  transform: translateY(-50%);
}

.trakstar :global(.rbox-jobs-group:not(:last-child)) {
  margin-bottom: 64px;
}

.trakstar :global(.rbox-opening-list .rbox-opening-position-info > *) {
  background: var(--lighterGray);
  padding: 4px 12px 6px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 24px;
  text-align: center;
  color: var(--gray);
  text-transform: uppercase;
  border: none;
}

.trakstar :global(.rbox-job-fullpage .rbox-opening-position-info > *) {
  background: var(--lightestGray);
  padding: 4px 12px 6px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 24px;
  text-align: center;
  color: var(--black);
  text-transform: uppercase;
  border: none;
}

.trakstar :global(.rbox-opening-position-info > *:not(:last-child)) {
  margin-right: 16px;
}

.trakstar :global(.rbox-opening-li:hover .rbox-opening-position-info > *) {
  color: var(--black);
}

.trakstar :global(.rbox-opening-li:hover .rbox-opening-li-title) {
  text-decoration: none;
  color: var(--black);
}

.trakstar :global(.rbox-opening-li):hover {
  border: 1px solid var(--lightGray) !important;
}

.trakstar :global(.rbox-jobs-group h3) {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 28px;
  margin-bottom: 16px;
}

.trakstar :global(.rbox-job-fullpage) {
  padding: 64px 108px;
  background: var(--offWhite);
}

.trakstar :global(.rbox-job-title) {
  font-size: 36px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 52px;
  margin-bottom: 16px;
}

.trakstar :global(.rbox-job-shortdesc) {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 24px;
  text-transform: uppercase;
  margin-bottom: 16px;
}

.trakstar :global(.rbox-job-longdesc) {
  border-top: none;
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 28px;
  padding-top: 32px;
}

.trakstar :global(.rbox-job-longdesc) p {
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 28px;
  max-width: 624px;
}

.trakstar :global(.rbox-job-longdesc) p:empty {
  display: none;
}

.trakstar :global(.rbox-job-longdesc) strong {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 32px;
}

.trakstar :global(.rbox-job-longdesc) ol {
  list-style: none;
  counter-reset: li;
}

.trakstar :global(.rbox-job-longdesc) ol li:before {
  content: counter(li) ".";
  margin-right: 14px;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.17px;
  line-height: 26px;
}

.trakstar :global(.rbox-job-longdesc) ol li {
  counter-increment: li;
}

.trakstar :global(.rbox-job-longdesc) ul {
  list-style-type: disc;
  list-style-position: inside;
  margin-bottom: 2em;
}

.trakstar :global(.rbox-job-longdesc) li {
  margin-bottom: 10px;
}

.trakstar :global(.rbox-form-actions) button,
.trakstar :global(.rbox-buttons) button {
  font-size: 12px;
  line-height: 24px;
  height: 58px;
  letter-spacing: 0.5px;
  padding: 16px 36px 16px;
  text-transform: uppercase;
  border-radius: 29px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in;
  min-width: 178px;
  font-weight: 500;
  border: 1.5px solid transparent;
  border-radius: 30px;
  background-image: linear-gradient(white, white),
    linear-gradient(to left, #5bc490, #4da884);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  mix-blend-mode: darken;
  text-shadow: none;
  box-shadow: none;
  appearance: none;
}

.trakstar :global(.rbox-form-actions) button:hover,
.trakstar :global(.rbox-buttons) button:hover {
  background: var(--green);
  color: var(--white);
}

.trakstar :global(.rbox-application-form) {
  border-top: 1px solid var(--lighterGray);
}
.trakstar :global(.rbox-application-form) fieldset {
  display: flex;
  flex-wrap: wrap;
}

.trakstar input[type="file"]::-webkit-file-upload-button,
.trakstar input[type="file"]::file-upload-button,
.trakstar input[type="file"]::file-selector-button {
  border: 2px solid #6c5ce7;
  padding: 0.2em 0.4em;
  border-radius: 0.2em;
  background-color: #a29bfe !important;
}

.trakstar :global(.rbox-application-form) fieldset input[type="email"],
.trakstar :global(.rbox-application-form) fieldset input[type="text"] {
  border: 1px solid var(--lightGray);
  border-radius: 8px;
  background-color: var(--white);
  line-height: 28px;
  font-size: 16px;
  padding: 13px 20px;
}

.trakstar :global(.rbox-application-form) fieldset input[type="email"]:hover,
.trakstar :global(.rbox-application-form) fieldset input[type="text"]:hover {
  border: 1px solid var(--green);
}

.trakstar :global(.rbox-application-form) label {
  margin-bottom: 6px;
  color: var(--gray);
  font-size: 14px;
  letter-spacing: 0.2px;
  line-height: 26px;
}

.trakstar :global(.rbox-application-form .rbox-field-wrapper) {
  width: 50%;
  max-width: 324px;
  padding-right: 24px;
}

.trakstar :global(.rbox-application-form #resume) {
  width: 100%;
}
.trakstar :global(.rbox-application-form #cover_letter) {
  width: 50%;
}

.trakstar :global(.rbox-error-msg) {
  display: block;
}

.trakstar :global(#rbox-google-captcha-field) {
  display: none;
}

.trakstar input[type="file"] {
  width: 100%;
}

.trakstar input[type="file"]:hover {
  cursor: pointer;
}

.trakstar input[type="file"]::-webkit-file-upload-button {
  font-size: 12px;
  line-height: 24px;
  height: 40px;
  letter-spacing: 0.5px;
  padding: 7px 31px;
  text-transform: uppercase;
  border-radius: 29px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in;
  min-width: 140px;
  font-weight: 500;
  border: 1.5px solid transparent;
  border-radius: 30px;
  background-image: linear-gradient(white, white),
    linear-gradient(to left, #5bc490, #4da884);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  mix-blend-mode: darken;
  text-shadow: none;
  box-shadow: none;
  appearance: none;
  cursor: pointer;
}


.trakstar input[type="file"]::file-upload-button {
  font-size: 12px;
  line-height: 24px;
  height: 40px;
  letter-spacing: 0.5px;
  padding: 7px 31px;
  text-transform: uppercase;
  border-radius: 29px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in;
  min-width: 140px;
  font-weight: 500;
  border: 1.5px solid transparent;
  border-radius: 30px;
  background-image: linear-gradient(white, white),
    linear-gradient(to left, #5bc490, #4da884);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  mix-blend-mode: darken;
  text-shadow: none;
  box-shadow: none;
  appearance: none;
  cursor: pointer;
  margin-right: 16px;
}
.trakstar input[type="file"]::file-selector-button {
  font-size: 12px;
  line-height: 24px;
  height: 40px;
  letter-spacing: 0.5px;
  padding: 7px 31px;
  text-transform: uppercase;
  border-radius: 29px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in;
  min-width: 140px;
  font-weight: 500;
  border: 1.5px solid transparent;
  border-radius: 30px;
  background-image: linear-gradient(white, white),
    linear-gradient(to left, #5bc490, #4da884);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  mix-blend-mode: darken;
  text-shadow: none;
  box-shadow: none;
  appearance: none;
  cursor: pointer;
  margin-right: 16px;
}

.trakstar input[type="file"]:hover::-webkit-file-upload-button {
  background: var(--green);
  color: var(--white);
}

.trakstar input[type="file"]:hover::file-upload-button {
  background: var(--green);
  color: var(--white);
}

.trakstar input[type="file"]:hover::file-selector-button {
  background: var(--green);
  color: var(--white);
}

.trakstar :global(.rbox-form-actions) {
  width: 100%;
  padding-top: 42px;
}

@media screen and (max-width: 1024px) {
  .trakstar :global(.rbox-job-fullpage) {
    padding: 64px 32px;
  }
}

@media screen and (max-width: 768px) {
  .subtitle {
    margin-bottom: 32px;
  }
  .trakstar {
    padding: 64px 0;
  }

  .trakstar :global(.rbox-job-fullpage) {
    padding: 32px 16px;
  }

  .trakstar :global(.rbox-job-shortdesc) {
    margin-bottom: 0;
  }

  .trakstar :global(.rbox-opening-list .rbox-opening-position-info) {
    position: static;
    transform: none;
    display: block;
    margin-top: 16px;
  }
  .trakstar :global(.rbox-application-form #cover_letter) {
    width: 100%;
  }
  .trakstar :global(.rbox-application-form .rbox-field-wrapper) {
    width: 100%;
    max-width: 100%;
  }

  .trakstar :global(.rbox-widget input), .trakstar :global(.rbox-widget textarea) {
    width: 100%;
  }
}
