.wysiwyg {
  padding: 170px 0 112px;
}

.hero {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 820px;
  z-index: -1;
  background-color: var(--black);
}

.content {
  background: var(--white);
  border-radius: 12px;
  padding-top: 96px;
}

.contentInner {
  width: 65%;
  margin: 0 auto;
}

.lastUpdated {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 24px;
  text-align: center;
  color: var(--lightGray);
  text-transform: uppercase;
}

.html {
  padding-top: 96px;
  color: var(--black);
}

.html h1,
.html h2,
.html h3 .html h4,
.html h5,
.html h6 {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 38px;
  margin-bottom: 24px;
}

.html h1:not(:first-child),
.html h2:not(:first-child),
.html h3 .html h4:not(:first-child),
.html h5:not(:first-child),
.html h6:not(:first-child) {
  margin-top: 60px;
}

.html p {
  margin-bottom: 22px;
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 28px;
}

.html a {
  color: var(--green);
}

.html a:hover {
  text-decoration: underline;
}

.html ol {
  list-style: none;
  counter-reset: li;
}

.html ol li:before {
  content: counter(li) ".";
  margin-right: 14px;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.17px;
  line-height: 26px;
}

.html ol li {
  counter-increment: li;
}

.html ul {
  list-style-type: disc;
  list-style-position: inside;
}

.html li {
  margin-bottom: 10px;
}

.wrapper {
  max-width: 620px;
  margin: 0 auto;
}

.title {
  font-size: 60px;
  line-height: 84px;
  font-weight: 500;
  margin-bottom: 24px;
  text-align: center;
  letter-spacing: 0.2px;
}

@media screen and (max-width: 1024px) {
  .contentInner {
    width: 100%;
    padding-left: 32px;
    padding-right: 32px;
  }
}

@media screen and (max-width: 768px) {
  .contentInner {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }

  .title {
    font-size: 48px;
    line-height: 64px;
  }
}
