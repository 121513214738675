.team {
  padding: 112px 0 96px;
  background: var(--offWhite);
}

.imageWrapper {
  max-width: 516px;
  margin: 0 auto;
  margin-bottom: 64px;
}

.innerWrapper {
  max-width: 624px;
  margin: 0 auto;
  text-align: center;
}

.text {
  margin-bottom: 48px;
}

.buttons > *:first-child {
  margin-right: 32px;
}

.buttons > * {
  margin-bottom: 32px;
}
