.hero {
  padding: 240px 0 130px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  min-height: 600px;
  align-items: center;
  text-align: center;
  position: relative;
  background-color: var(--black);
}

.hero > div {
  position: relative;
  z-index: 1;
}

.hero * {
  color: var(--white);
}

:global(.contact-us) .hero {
  color: var(--black);
  background: var(--white);
  padding: 154px 0 70px;
}

:global(.contact-us) .hero * {
  color: var(--black);
  background: var(--white);
}

:global(.contact-us) .hero .subtitle {
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 28px;
  text-align: center;
}

:global(.sustainable-finances) .title,
:global(.sustainable-innovation) .title,
:global(.sustainable-communities) .title {
  order: 1;
}
:global(.sustainable-finances) .subtitle,
:global(.sustainable-innovation) .subtitle,
:global(.sustainable-communities) .subtitle {
  order: 0;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 6px;
}

.wrapper {
  max-width: 620px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 60px;
  line-height: 84px;
  font-weight: 500;
  margin-bottom: 24px;
  text-align: center;
  letter-spacing: 0.2px;
}

.subtitle {
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.2px;
  line-height: 38px;
}

@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  5% {
    transform: scale(1, 1) translateY(0);
  }
  15% {
    transform: scale(1, 1) translateY(-16px);
  }
  25% {
    transform: scale(1, 1) translateY(0);
  }

  50% {
    transform: scale(1, 1) translateY(0);
  }
}

.scrollIcon {
  margin: 42px auto 0;
  width: 32px;
  height: 32px;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

.scrollIcon > * {
  animation: 4s bounce infinite;
}

@media screen and (max-width: 1024px) {
  .hero {
    padding: 170px 0 100px;
    min-height: 500px;
  }
}

@media screen and (max-width: 768px) {
  .hero {
    padding: 150px 0 64px;
    min-height: 400px;
  }

  .title {
    font-size: 32px;
    line-height: 48px;
  }

  .subtitle {
    font-size: 16px;
    line-height: 28px;
  }
}
