.teamListing {
  padding: 112px 0 64px;
  background: var(--white);
  position: relative;
  background: linear-gradient(359.67deg, #ffffff 0%, #f6f8fb 100%);
}

.teamListing h2 {
  text-align: center;
}

.grid {
  margin-top: 76px;
  display: grid;
  grid-gap: 64px 24px;
  grid-template-columns: repeat(4, 1fr);
}

.rect {
  position: relative;
  cursor: url("./cursorClose.svg") 32 32, pointer;
}

.memberInfo {
  padding: 0 8px;
}

.cursor {
  display: none !important;
  position: absolute;
  z-index: 3;
  pointer-events: none;
}

.image {
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 16px;
}

.name {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.17px;
  line-height: 26px;
  margin-bottom: 6px;
}

.socials {
  display: flex;
  margin-left: 24px;
}

.socials > * {
  margin-left: 8px;
}

.member {
  cursor: url("./cursorOpen.svg") 32 32, pointer;
}

.socials > * {
  cursor: pointer;
}

.member:hover .image {
  box-shadow: 0 0 20px 0 rgba(181, 187, 203, 0.5);
}

.member .socials > * {
  transform: scale(0.8);
  margin-left: 4px;
}

.linkedin:hover svg *,
.linkedin:hover svg {
  fill: var(--black);
}

.nameRow {
  display: flex;
  align-items: center;
  align-content: center;
  margin-bottom: 8px;
}

.nameRow h2 {
  margin-bottom: 0;
}

.title {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 24px;
  text-transform: uppercase;
  color: var(--gray);
}

.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  overflow-y: scroll;
  background: var(--offWhite);
  z-index: 3000000;
}

.row {
  display: flex;
  flex-direction: row;
  padding: 44px 0;
}

.titleRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.aside {
  width: 23.5%;
  margin-right: 7%;
  flex-shrink: 0;
}

.asideGroup {
  padding: 32px 0 32px 16px;
  border-bottom: 1px solid var(--lighterGray);
}

.asideGroupTitle {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 24px;
  text-transform: uppercase;
  margin-bottom: 4px;
}

.asideItemTitle {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.15px;
  line-height: 26px;
}

.asideItem {
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: 8px;
}

.content {
  padding-top: 24px;
  width: 70%;
}

.contentGroup p {
  margin-bottom: 1em;
}

.contentInner {
  max-width: 516px;
}

.content > *:first-child {
  margin-bottom: 8px;
}

.contentGroup {
  margin-top: 48px;
}

@media screen and (max-width: 1024px) {
  .teamListing {
    padding: 64px 0 32px;
  }

  .grid {
    margin-top: 16px;
    grid-gap: 32px 24px;
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (hover: none) {
  .rect .cursor {
    position: absolute;
    display: block !important;
    top: 16px !important;
    right: 0 !important;
    left: auto !important;
  }
}

@media screen and (max-width: 768px) {
  .aside {
    width: 50%;
  }
  .row {
    flex-direction: column;
    padding: 16px 0;
  }
  .content {
    width: 100%;
  }
  .grid {
    margin-top: 16px;
    grid-gap: 32px 24px;
    grid-template-columns: repeat(2, 1fr);
  }
  .asideGroup {
    display: none;
  }
}
