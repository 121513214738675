.serviceIntro {
  padding: 96px 0 128px;
  background: var(--white);
}

.icon {
  width: 58px;
  height: 58px;
  margin: 0 auto;
  position: relative;
  margin-bottom: 24px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.image {
  width: 40%;
  position: relative;
  z-index: 10;
}

.imageWrapper {
  border-radius: 12px;
  position: absolute;
  left: 0;
  z-index: 10;
  top: 0;
  right: 0;
}

.imageWrapper img {
  border-radius: 12px;
  overflow: hidden;
}

.iconWrapper {
  position: absolute;
  bottom: -50px;
  z-index: 30;
  left: 8px;
}

.content {
  width: 40%;
}

.text {
  color: var(--black);
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 28px;
}

.content {
}

@media screen and (max-width: 1024px) {
  .serviceIntro {
    padding: 64px 0 96px;
  }
}

@media screen and (max-width: 768px) {
  .serviceIntro {
    padding: 24px 0 96px;
  }

  .row {
    flex-direction: column;
  }
  .iconWrapper {
    bottom: auto;
    top: -16px;
  }
  .image {
    width: 100%;
    margin-bottom: 32px;
  }
  .imageWrapper {
    position: static;
  }
  .content {
    width: 100%;
  }
  .centeredIntro {
    padding: 64px 0;
  }
}
