.verticalNumberedBlocks {
  padding: 128px 0 64px;
  background: var(--white);
}

.row {
  display: flex;
  flex-direction: row;
}

.left {
  position: sticky;
  top: 20px;
  left: 0;
  width: 50%;
  padding-right: 10%;
}

.right {
  width: 50%;
}

.cards {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 24px;
}

.blocks {
  width: 50%;
}

.block {
  position: sticky;
  top: 0;
  color: var(--black);
  padding: 42px 42px 24px 68px;
  border-radius: 12px;
  background: var(--offWhite);
  margin-bottom: 24px;
  position: relative;
}

.blockTitle {
  font-size: 20px;
  letter-spacing: 0.2px;
  line-height: 32px;
  margin-bottom: 16px;
}

.blockNumber {
  position: absolute;
  top: 34px;
  left: 28px;
  color: #15958c;
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 44px;
  text-align: center;
}
