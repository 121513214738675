.imageAside {
  padding: 112px 0 96px;
  background: var(--white);
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.image {
  position: relative;
  width: 50%;
}

.text {
  color: var(--black);
}

.image img {
  border-radius: 12px;
  overflow: hidden;
}

.content {
  width: 50%;
  padding-right: 10%;
}

.imageAsideWide .image {
  width: 40%;
}

.imageAsideWide .content {
  width: 60%;
  padding-right: 10%;
}

.buttonWrapper {
  margin-top: 48px;
}

.badge {
  position: absolute;
  top: -24px;
  right: -24px;
  z-index: 2;
  border-radius: 50%;
}

:global(.opportunities) .imageAside {
  background: var(--offWhite);
}

:global(.opportunities) .badge {
  right: auto;
  left: -24px;
}

@media screen and (max-width: 768px) {
  .imageAside {
    padding: 96px 0 96px;
  }

  .row {
    flex-direction: column-reverse;
  }

  .image {
    margin-bottom: 32px;
  }

  

  :global(.opportunities) .badge {
    right: auto;
    left: -12px;
  }

  .imageAside .content,
  .imageAside .image {
    width: 100%;
    padding-right: 0;
  }
}
