.tabsImageAside {
  padding: 112px 0 64px;
  background: var(--offWhite);
}

.tabsImageAsideWhite {
  background: var(--white);
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.tabImage {
  width: 50%;
  position: relative;
  font-size: 0;
}

.tabImage img {
  border-radius: 12px;
  overflow: hidden;
}

.content {
  width: 50%;
}

.buttonWrapper {
  margin-top: 48px;
}

.top {
  text-align: center;
  max-width: 620px;
  margin: 0 auto 50px;
}

.text {
  color: var(--black);
  padding-bottom: 58px;
}

.tabs {
  display: flex;
  margin-bottom: 42px;
}

.tab {
  flex-basis: 33%;
  flex-grow: 0;
  text-align: center;
  border-bottom: 1px solid var(--lightGray);
  cursor: pointer;
  padding-bottom: 2px;
  color: var(--lightGray);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 24px;
  text-transform: uppercase;
  padding-bottom: 10px;
}

.tab:focus {
  outline: none;
}

.tabActive {
  color: var(--black);
  border-bottom: 1px solid var(--green);
}

.tab:not(.tabActive):hover {
  color: var(--green);
}

.tabContent {
  background: var(--white);
  display: flex;
  border-radius: 12px;
  overflow: hidden;
}

.tabContentHidden {
  height: 0;
}

.tabContent > * {
  flex: 1 1 auto;
  width: 50%;
  align-items: stretch;
}

.tabInfo {
  padding: 64px;
}

.tabText {
  color: var(--black);
  margin-bottom: 0px;
}

.tabsImageAside a {
  margin-top: 32px;
}

:global(.opportunities) .tabContent {
  background: var(--offWhite);
}

:global(.overview) .tabContent {
  background: var(--offWhite);
}

:global(.overview) .tabsImageAside {
  padding: 112px 0;
}

@media screen and (max-width: 1024px) {
  .tabsImageAside.tabsImageAside {
    padding: 96px 0;
  }

  .top {
    margin: 0 auto 48px;
  }

  .text {
    padding-bottom: 24px;
  }

  .tabImage {
    min-height: 480px;
  }

  .tabContent > * {
    flex: 1 1 auto;
    width: 100%;
    align-items: stretch;
  }

  .tabContent {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 768px) {


  .tabsImageAside.tabsImageAside {
    padding: 96px 0;
  }

  :global(.opportunities) .tabsImageAside {
    padding: 64px 0 48px;
  }

  .top {
    margin: 0 auto 32px;
  }

  .text {
    padding-bottom: 24px;
  }

  .tabInfo {
    padding: 64px 24px;
  }
}
