.history {
  padding: 0px 0 96px;
  background: var(--offWhite);
}

.headingRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 80px;
}

.headingRow > *:first-child {
  max-width: 840px;
}

.images {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.preview {
  width: 40%;
  position: relative;
}

.preview:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: -12.5%;
  bottom: 0;
  width: 1px;
  background: var(--lighterGray);
}

.previewImage {
  padding-bottom: 75%;
  position: relative;
  margin-bottom: 20px;
  border-radius: 12px;
  overflow: hidden;
}

.items {
  width: 50%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px 24px;
}

.item {
  cursor: pointer;
  opacity: 0.3;
}

.itemActive,
.item:hover {
  opacity: 1;
}

.item:focus {
  outline: 0;
}

.itemImage {
  position: relative;
  padding-bottom: 75%;
  border-radius: 10px;
  overflow: hidden;
  font-size: 0;
  border: 1px solid transparent;
}

.itemActive .itemImage {
  border: 1px solid var(--green);
}

.itemImage * {
  display: block!important;
}

.previewTitle,
.itemTitle {
  margin-top: 8px;
  color: var(--green);
  font-size: 36px;
  line-height: 44px;
}

.previewTitle {
  margin-bottom: 16px;
}

@media screen and (max-width: 1024px) {
  .items {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 40px 24px;
  }

  .preview:after {
    display: none;
  }

  .preview {
    margin-bottom: 32px;
  }

  .headingRow,
  .images {
    flex-direction: column;
  }

  .headingRow > *:first-child,
  .headingRow > *:last-child {
    width: 100%;
    padding-left: 0;
  }

  .preview,
  .items {
    width: 100%;
  }

  .content {
    margin-top: 32px;
  }
}

@media screen and (max-width: 768px) {
  .items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px 24px;
  }
}
